<template>
  <div :class="{ '-loading': !$page }">
    <template v-if="$page">
      <header class="header" :style="backgroundImage">
        <div v-scroll:reveal="{ delay: 450 }">
          <Icon id="study" class="header__badge"/>
        </div>
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__title">{{ $page.header.title }}</h1>
        <h2 v-scroll:reveal="{ delay: 650 }" class="header__subtitle">{{ $page.header.subtitle }}</h2>
      </header>

      <FilterBar v-bind="filterConfig" :count="count" :is-index-filtered="isIndexFiltered"/>

      <section class="section -double-pad -first">
        <template v-if="isIndexReady">
          <p class="section__legend">
            <span v-if="count > 0">{{ count }} </span><span>{{ getResultLabel(count) }}</span>
          </p>
          <div class="study-card-list">
            <StudyCard
                v-for="study in currentIndexPage"
                :key="study.id"
                :study-id="study.id"
                class="study-card-list__item"
            />
          </div>
        </template>

        <div v-else class="pending">
          <img src="/badges/pending.png" alt="" class="pending__img"/>
          <p>{{ $t('app.pending') }}</p>
        </div>
      </section>

      <Pagination
          v-if="hasPagination"
          :current-page="currentPage"
          :item-per-page="itemPerPage"
          :total-item="index.length"
      />

      <EcoCta
          :title="$page.ecosystem.title"
          :subtitle="$page.ecosystem.subtitle"
          :link-label="$page.ecosystem.linkLabel"
          class="-index"
      />
    </template>
  </div>
</template>

<script>
import {IndexPageMixin, PageMetaMixin} from '../mixins'
import {filterOptionFactory} from '@/helpers'
import {StudyCard} from '@/components/cards'
import {FilterBar} from '@/components/filters'
import {Pagination} from '@/components/nav'
import {EcoCta} from '@/components/partials'
import http from '@/extensions/Http'

const createOptions = filterOptionFactory('id', 'name')

export default {
  name: 'StudyIndex',
  beetPage: 'studiesindex',
  components: {StudyCard, FilterBar, Pagination, EcoCta},
  mixins: [IndexPageMixin, PageMetaMixin],
  data() {
    return {
      searchResults: null,
      isIndexReady: true,
      isFilterOpen: false,
      itemPerPage: 9
    }
  },
  computed: {
    backgroundImage() {
      return this.$device.tablet
          ? `background-image: url('/images/banner_study.jpg')`
          : `background-image: url('/images/banner_study_m.jpg')`
    },
    isIndexFiltered() {
      const {sect} = this.$route.query
      return !!sect
    },
    filterConfig() {
      return {
        scope: 'study',
        label: this.$t('app.object.study'),
        placeholder: this.$t('app.filters.placeholder'),
        filters: [
          {
            name: this.$t('app.object.appSector'),
            queryKey: 'sect',
            options: this.formatFilterOptions(createOptions(this.$beet.sectors)),
            isMultiple: true,
            hasIcon: true
          }
        ]
      }
    },
    index() {
      const {sect: sectorQueryValue} = this.$route.query
      const filterBySector = this.createFilterBySector(sectorQueryValue)
      return !this.searchResults
          ? this.sortedIndex(this.$store.state.randomStudyIndex.filter(filterBySector))
          : this.sortedIndex(this.searchResults.filter(filterBySector))
    }
  },
  watch: {
    '$route.query.q': {
      immediate: true,
      handler(to, from) {
        if (!from && to) {
          this.isIndexReady = false
          this.getSearchResults(to)
        } else if (from !== to && to) {
          this.isIndexReady = false
          this.getSearchResults(to)
        } else {
          this.searchResults = null
        }
      }
    }
  },
  methods: {
    formatFilterOptions(filter, alphabeticalSort = true) {
      return alphabeticalSort ? Object.entries(filter).sort((a, b) => {
        if (a[1] < b[1]) {
          return -1
        } else if (a[1] > b[1]) {
          return 1
        }
        return 0
      }) : Object.entries(filter)
    },
    sortedIndex(index) {
      return this.isIndexFiltered && !this.searchResults ? index.sort(this.sortByTitle) : index
    },
    getSearchResults(query) {
      http
          .get(`api/search/studies?s=${query}`)
          .then(this.mapSearchResults)
          .catch(() => (this.searchResults = null))
    },
    mapSearchResults({data}) {
      if (!data.length) {
        this.searchResults = []
      } else {
        this.searchResults = data.map(id => {
          return this.$beet.studies[id]
        })
      }
      this.isIndexReady = true
    },
    createFilterBySector(sectorValue) {
      if (!sectorValue) return () => true
      const safeQueryValue = Array.isArray(sectorValue) ? sectorValue : [sectorValue]
      return ({sectors}) => safeQueryValue.some(selectedId => sectors.indexOf(Number(selectedId)) > -1)
    },
    getResultLabel(count) {
      return count > 0 ? this.$t('app.filters.results') : this.$t('app.filters.noResult')
    }
  }
}
</script>
