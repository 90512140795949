<template>
  <nav class="navbar">
    <div :class="['navbar__is-auth-container', {'--active': newsletterNoticeOpened === true}]">
      <div style="overflow: hidden">
        <div class="navbar__is-auth">
          <RawHtml
              :html="$t('app.nav.newsletter').replace('[URL]', 'https://app.cyberimpact.com/clients/48070/subscribe-forms/647D93F0-265A-4C9A-878D-C96CE69258B8')"/>
          <button class="navbar__is-auth-button" @click.stop="newsletterNoticeOpened = false">
            <Icon id="close"/>
          </button>
        </div>
      </div>
    </div>
    <div v-if="isAuth" class="navbar__is-auth">
      <p>{{ $t('app.nav.auth') }}</p>
    </div>

    <div class="navbar__main">
      <aside>
        <LangRouterLink :to="{ name: 'home' }">
          <img :src="`/images/VitrineIACI-logo-${$lang.current}.svg`" alt="Vitrine - IA" class="navbar__logo"/>
        </LangRouterLink>
      </aside>

      <div class="navbar__menu">
        <div class="navbar__links">

          <div class="navbar__link link" @click="repertoryOpen = !repertoryOpen">
            {{ $t('app.nav.repertory') }}
            <Icon id="arrow-drop" class="navbar__dropdown--icon" :class="{'-reverse': repertoryOpen}"/>

            <DropDown :is-open="repertoryOpen" style="position: absolute;">
              <div class="navbar__dropdown">
                <LangRouterLink class=" link" :to="{ name: 'businessIndex' }">
                  {{ $t('app.instant.menu.complete') }}
                </LangRouterLink>
                <LangRouterLink class="link" :to="{ name: 'instant' }">
                  {{ $t('app.instant.menu.overview') }}
                </LangRouterLink>
              </div>
            </DropDown>
          </div>

          <LangRouterLink class="navbar__link link" :to="{ name: 'studyIndex' }">
            {{ $t('app.nav.studies') }}
          </LangRouterLink>

          <div class="navbar__link link" @click="toolsOpen = !toolsOpen">
            {{ $t('app.nav.tools') }}
            <Icon id="arrow-drop" class="navbar__dropdown--icon" :class="{'-reverse': toolsOpen}"/>

            <DropDown :is-open="toolsOpen" style="position: absolute;">
              <div class="navbar__dropdown">
                <LangRouterLink class="link" :to="{ name: 'maturity' }">
                  {{ $t('app.nav.maturity') }}
                </LangRouterLink>
                <LangRouterLink class="link" :to="{ name: 'documentIndex' }">
                  {{ $t('documents.page') }}
                </LangRouterLink>
              </div>
            </DropDown>
          </div>

          <a target="_blank" rel="noopener" class="navbar__link link" :href="moduleUrl" @click="$event.target.blur()">
            {{ $t('app.nav.ecosystem') }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import http from '@/extensions/Http'
import {DropDown, Icon, RawHtml} from '@/components/global'

export default {
  name: 'SiteNav',
  components: {Icon, DropDown, RawHtml},
  data() {
    return {
      newsletterNoticeOpened: true,
      repertoryOpen: false,
      toolsOpen: false
    }
  },
  computed: {
    showLogin() {
      if (this.$store.state.user && this.$store.state.user.role === 'BusinessOwner') return false
      return true
    },
    isAuth() {
      return this.$store.state.user.isAuth
    },
    openLoginPanelLink() {
      return {
        name: this.$route.name,
        query: {...this.$route.query, connexion: true}
      }
    },
    moduleUrl() {
      return this.$lang.current === 'fr' ? process.env.VUE_APP_MODULE_URL : `${process.env.VUE_APP_MODULE_URL}/en`
    }
  },
  watch: {
    toolsOpen(to) {
      if (to && this.repertoryOpen) this.repertoryOpen = false
    },
    repertoryOpen(to) {
      if (to && this.toolsOpen) this.toolsOpen = false
    }
  },
  methods: {
    logout() {
      http.$post('/api/logout').then(() => {
        this.$store.dispatch('updateIsAuth', {bool: false})
      })
    }
  }
}
</script>
