<template>
  <section id="top" class="section -first filter-bar">
    <FilterBarSearch :scope="scope" :placeholder="placeholder" />
    <Component
      :is="FilterBarComponent"
      :label="label"
      :filters="filters"
      :scope="scope"
      :is-index-filtered="isIndexFiltered"
      :count="count"
      :mention="mention"
    />
  </section>
</template>

<script>
import FilterBarDesktop from './FilterBarDesktop.vue'
import FilterBarSearch from './FilterBarSearch.vue'
import { Modal } from '@/components/modal'

export default {
  name: 'FilterBar',
  components: { Modal, FilterBarDesktop, FilterBarSearch },
  props: {
    scope: { type: String, required: true },
    label: { type: String, required: true },
    placeholder: { type: String, default: null },
    filters: { type: Array, default: Array },
    isIndexFiltered: { type: Boolean, default: false },
    mention: { type: String, default: null },
    count: { type: Number, required: true }
  },
  computed: {
    FilterBarComponent() {
      return FilterBarDesktop
    }
  }
}
</script>
