<template>
  <aside class="study-side-bar">
    <figure v-if="logo" class="study-side-bar__logo" :style="backgroundImage" :title="name" />

    <div v-if="businessActor" class="study-side-bar__actor">
      <template v-if="isStartup">
        <Icon id="startup" />
        <p>{{ $t('app.object.startup') }}</p>
      </template>

      <template v-else>
        <Icon :id="getActorIcon(businessActor.id)" />
        <p>{{ businessActor.name }}</p>
      </template>
    </div>
    <div v-if="studySectors.length" class="study-side-bar__sectors">
      <LangRouterLink
        v-for="sector in studySectors"
        :key="sector.id"
        :to="{ name: 'studyIndex', query: { sect: String(sector.id) } }"
        class="tag -icon"
        :title="sector.name"
        ><Icon :id="getSectorIcon(sector.id)" /><span class="tag__tip">{{ sector.name }}</span></LangRouterLink
      >
    </div>

    <LangRouterLink
      class="button -link -dark study-side-bar__link"
      :to="{ name: 'businessShow', params: { businessSlug: slug } }"
      >{{ $t('app.links.businessSingle') }}</LangRouterLink
    >

    <button v-if="$device.customlaptop" class="business-side-contact__scroll-up link -scroll-up" @click="scrollTop">
      {{ $t('app.nav.scrollUp') }}
      <Icon id="arrow" />
    </button>
  </aside>
</template>

<script>
import { Icon } from '@/components/global'

export default {
  name: 'StudySideBar',
  components: {
    Icon
  },
  props: {
    slug: { type: String, require: true },
    name: { type: String, require: true },
    isStartup: { type: Boolean, default: false },
    actor: { type: Number, default: null },
    sectors: { type: Array, default: Array },
    logo: { type: String, default: null }
  },
  computed: {
    studySectors() {
      const { sectors = [] } = this.$beet || {}
      return this.sectors.map(id => sectors[id])
    },
    businessActor() {
      return this.actor ? this.$beet.actorTypes[this.actor] : null
    },
    backgroundImage() {
      return `background-image: url('${this.logo}')`
    }
  },
  methods: {
    getActorIcon(id) {
      return `act_${id}`
    },
    getSectorIcon(id) {
      return `sect_${id}`
    },
    scrollTop() {
      this.$scroll.toTop(0, 300)
      this.$router.replace({ hash: '' })
    }
  }
}
</script>
