export default {
    companyName: 'Vitrine AI Québec',
    portalButton: 'Export Portal',
    logoutButton: 'Log out',
    by: 'By',
    updated: 'Entry updated on',
    publishedStudy: 'Study published on',
    pending: 'Search in progress',
    back: 'Back',
    previous: 'Previous',
    next: 'Next',
    submit: 'Submit',
    send: 'Send',
    yes: 'Yes',
    no: 'No',
    select: 'Select an option',
    globalError: 'An error has occurred. Please try again later or contact us if the problem persists.',
    search: 'Find your organization',
    vitrine: 'My Vitrine',
    other: ' and {nb} other region | and {nb} other regions',
    profile: 'Is this your profile?',
    miria: {
        description: 'The beta version, launched on September 11th, is now available in a limited capacity.',
        highlight: 'We invite you to register your company and discover the Miria experience.</br> In order to receive the inscription link:',
        note: '<a href="mailto:miria@boitebeet.com">Contact us</a>',
        title: 'The <em>Miria</em> AI agent: a new feature to promote innovative Quebec companies in AI.'
    },
    nav: {
        newsletter: 'Vitrine IA is currently evolving. <a href="[URL]" target="_blank">Subscribe to our newsletter</a> to be the first to hear about the latest updates.',
        repertory: 'Directory',
        studies: 'Case Studies',
        ecosystem: 'Ecosystem',
        funding: 'Financing',
        auth: 'You are connected to the Export Portal',
        backStudies: 'Back to case studies',
        backBusinesses: 'Back to the directory',
        backHome: 'Back to Home Page',
        scrollUp: 'Top of the page',
        tools: 'Tools',
        maturity: 'AI Maturity'
    },
    footer: {
        subscriptionButton: 'Subscribe to the newsletter',
        actorTypes: 'Types of actor',
        categories: 'Types of services',
        resources: 'Tools',
        maturity: 'AI Maturity Assessment Tool',
        ethic: 'Ethics review (coming soon)',
        terms: 'Terms',
        glossary: 'Glossary'
    },
    links: {
        sectorCard: 'Explore this sector',
        studyCard: 'View this case study',
        businessSingle: 'View company profile',
        businessContact: 'Contact the organization',
        businessWebsite: 'Visit the organization\'s website',
        studySingle: 'View the case study',
        storySingle: 'Read more',
        ecosystem: 'Discover the Vitrine AI Québec',
        details: 'details'
    },
    filters: {
        isStartup: 'Is a Startup',
        title: 'Filters',
        filterButton: 'Filters',
        region: 'Regions',
        placeholder: 'Search for a company, a sector, an AI technology, etc.',
        more: 'View more results',
        noResult: 'No result',
        results: 'result(s)',
        display: 'Show',
        reset: 'Clear',
        tech: 'AI technologies'
    },
    object: {
        business: 'Organization',
        study: 'Case study',
        sector: 'Sector',
        appSector: 'Sectors of application',
        activitySector: 'Sector of activity',
        actorType: 'Types of actor',
        businessTag: 'AI technologies',
        region: 'Region',
        category: 'Expertises',
        startup: 'Startup',
        startupStart: 'Startup created in {year}',
        startups: 'Startups'
    },
    cookies: {
        mainButton: 'Accept',
        secondButton: 'Accept only necessary',
        content:
            'This website uses cookies on your device. We use this information to improve and personalize your browsing experience. To find out more, see our ',
        linkLabel: 'terms of use'
    },
    ariaLabel: {
        closeMenu: 'Close menu',
        openFilter: 'Open filters',
        closeFilter: 'Close filters',
        search: 'Launch a search',
        closeConnexion: 'Close login',
        closeReset: 'Close reset password',
        closeModal: 'Close modal',
        nextPage: 'Load next page',
        previousPage: 'Load previous page',
        more: 'See more',
        less: 'See less',
        footerLogo: 'Powered by Forum IA Québec',
        print: 'Print page',
        share: 'Share page',
        shareMore: 'Open share options',
        contact: 'Contact us',
        switch: 'Basculer en français'
    },
    instant: {
        link: {
            title: 'Get a quick and concise overview of the organizations listed in our AI ecosystem directory',
            text: 'The Overview allows you to explore stats and summaries by region.',
            linkLabel: 'Take a look at the Overview',
        },
        menu: {
            complete: 'Complete Directory',
            overview: 'Overview'
        },
        header: {
            title: 'The Directory at a glance',
            subtitle: 'Get a quick and concise overview of the organizations listed in our AI ecosystem directory'
        },
        footer: {
            title: 'Search across all AI organizations',
            text: 'Use the search filters in our Directory to find the organization that will be the perfect match for your needs.',
            link: 'Consult the directory'
        },
        total: 'Number of organizations in the directory',
        available: 'According to available data',
        startups: 'Number of start-ups listed',
        stats: 'Get regional statistics',
        select: 'Select a region on the map to get regional statistics.',
        missing: 'Is an organization missing in your region?',
        next: 'Next region',
        suggest: 'Make a suggestion',
        orgRegion: 'Organization in the region | Organization in the region | Organizations in the region',
        startupsRegion: 'Start-up in the region | Start-up in the region | Start-ups in the region',
        dynamic: 'Most dynamic sector',
        actorType: 'Types of actors',
        sectors: 'Sectors of application',
        small: 'small | small | small',
        medium: 'medium | medium | medium',
        big: 'large | large | large',
        regional: {
            title: 'Profile of the Artificial Intelligence Ecosystem in the Greater Québec City Region',
            subtitle: 'Get a quick and concise overview of the data from our directory of organizations in the Capitale-Nationale and Chaudière-Appalaches AI ecosystem.',
            notes: `<p>This showcase was developed in partnership with the economic development agency <b>Québec International</b> and the <b>Université Laval’s Intelligence and Data Institute (IID)</b>, proud contributors to the development of the AI sector in the greater Québec City area.</p><p>Are you an AI company and your organization is not featured in the profile? Create a profile and get the word out!</p>`,
            total: 'Number of organizations in the 2 regions',
            seeAlso: 'Consult  :',
            allRegion: 'Explore all results in all regions',
            cta_consult: 'Visit the website',
            cta_1_title: 'Is your company looking to launch an AI project?',
            cta_1_text: 'Québec International can help you throughout the creation process.',
            cta_2_title: 'Do you have an AI research project in mind?',
            cta_2_text: 'Université Laval’s Intelligence and Data Institute (IID) can help!'
        }
    }
}
