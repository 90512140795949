<template>
  <div :class="['study-card', { '-hover': hover }]">
    <div>
      <div @mouseenter="hover = true" @mouseleave="hover = false">
        <LangRouterLink
          :to="{ name: 'studyShow', params: { studySlug: study.slug } }"
          class="study-card__title"
          @focus.native="hover = true"
          @blur.native="hover = false"
          >{{ getTitle(study.title) }}</LangRouterLink
        >
      </div>
      <p class="study-card__subtitle">{{ $t('app.by') }} {{ business.name }}</p>
    </div>
    <div class="study-card__sectors">
      <LangRouterLink
        v-for="sector in sectors"
        :key="sector.id"
        :to="{ name: 'studyIndex', query: { sect: String(sector.id) }, hash: '#top' }"
        class="tag -icon"
        :title="sector.name"
        ><Icon :id="getSectorIcon(sector.id)" /><span class="tag__tip">{{ sector.name }}</span></LangRouterLink
      >
      <span v-if="extraSector > 0" class="tag -icon">+{{ extraSector }}</span>
    </div>
  </div>
</template>

<script>
import _shuffle from 'lodash.shuffle'

export default {
  name: 'StudyCard',
  props: {
    studyId: { type: Number, required: true }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    study() {
      return this.$beet.studies[this.studyId]
    },
    business() {
      return this.$beet.businesses[this.study.business]
    },
    maxSector() {
      return this.$device.tablet ? 5 : 4
    },
    sectors() {
      const { sectors = [] } = this.$beet || {}
      const randomized = _shuffle(this.study.sectors)
      if (this.study.sectors.length <= 5) return randomized.sort(this.sortByQuery).map(id => sectors[id])

      return randomized
        .sort(this.sortByQuery)
        .map(id => sectors[id])
        .slice(0, this.maxSector)
    },
    extraSector() {
      return this.study.sectors.length - this.maxSector
    },
    safeQuerySect() {
      const currentQueryValue = this.$route.query.sect || []
      return Array.isArray(currentQueryValue) ? currentQueryValue : [currentQueryValue]
    }
  },
  methods: {
    sortByQuery(a, b) {
      const { sect } = this.$route.query
      if (!sect) return
      if (this.safeQuerySect.indexOf(String(a)) !== -1 && this.safeQuerySect.indexOf(String(b)) === -1) {
        return -1
      } else if (this.safeQuerySect.indexOf(String(b)) !== -1 && this.safeQuerySect.indexOf(String(a)) === -1) {
        return 1
      }
      return 0
    },
    getTitle(title) {
      return title.length < 80 ? title : `${title.substring(0, 70)} ...`
    },
    getSectorIcon(id) {
      return `sect_${id}`
    }
  }
}
</script>
